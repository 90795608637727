<template>
  <v-container class="m-0 p-0">
    <v-row dense align="center" justify="space-between">
      <v-col cols="auto">
        <v-btn-toggle v-model="tipo" borderless>
          <v-btn value="texto" x-small @click="$emit('tipo','texto')">
            <span class="hidden-sm-and-down">Texto</span>
            <v-icon x-small right>mdi-card-text-outline</v-icon>
          </v-btn>
          <v-btn value="lista" x-small @click="$emit('tipo','lista')">
            <span class="hidden-sm-and-down">Lista</span>
            <v-icon x-small right>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="auto">
        <v-btn-toggle v-model="estilo" borderless>
          <v-btn :value="item.title" :disabled="tipo=='lista'" x-small v-for="(item,key,index) in itens" :key="index" @click="$emit('estilo',item.cls)">
            <span class="hidden-sm-and-down">{{item.title}}</span>
            <v-icon x-small right>{{item.icon}}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "ementaviewformat",
  components: {},
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      itens: [
        { title: "Justificado", icon: "mdi-format-align-justify", cls: "text-justify" },
        { title: "Esquerda", icon: "mdi-format-align-left", cls: "text-left" },
        { title: "Centro", icon: "mdi-format-align-center", cls: "text-center" },
        { title: "Direita", icon: "mdi-format-align-right", cls: "text-right" },
      ],
      estilo: "Justificado",
      tipo: "texto",
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
